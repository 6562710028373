<template>
  <div class="content" id="anmeldedaten">
    <appSideNavigation></appSideNavigation>
    <appModal></appModal>
    <div class="main-content-container-closed">
      <div class="site-header">
        <h3>{{name}}</h3>
      </div>
      <div class="site-content">
        <div class="form-container">
          <div class="row">
            <div class="twelve columns">
              <div class="input-div">
                  <div>
                    <h5>Derzeitiges Passwort</h5>
                    <input class="input" v-model="userCurrentPassword" type="password" id="input-current-password" @focus="focusFunction" @blur="blurFunction">
                  </div>
                  <div class="icon">
                    <font-awesome-icon v-if="showCurrentPasswordInput" class="svg" icon="eye" @click="toggleInputType('input-current-password', 'current-password')" title="Passwort verstecken" />
                    <font-awesome-icon v-else class="svg" icon="eye-slash" @click="toggleInputType('input-current-password', 'password')" title="Passwort anzeigen"/>
                  </div>
              </div>
            </div>
            <div class="twelve columns">
              <div class="input-div">
                  <div>
                    <h5>Neues Passwort</h5>
                    <input class="input" v-model="userPassword" type="password" id="input-password" @focus="focusFunction" @blur="blurFunction">
                  </div>
                  <div class="icon">
                    <font-awesome-icon v-if="showPasswordInput" class="svg" icon="eye" @click="toggleInputType('input-password', 'new-password')" title="Passwort verstecken" />
                    <font-awesome-icon v-else class="svg" icon="eye-slash" @click="toggleInputType('input-password', 'new-password')" title="Passwort anzeigen"/>
                  </div>
              </div>
            </div>
            <div class="twelve columns">
              <div class="input-div">
                <div>
                  <h5>Neues Passwort wiederholen</h5>
                  <input class="input" v-model="userPasswordRepeat" type="password" id="input-password-repeat" @focus="focusFunction" @blur="blurFunction">
                </div>
                <div class="icon">
                  <font-awesome-icon v-if="showPasswordRepeatInput" class="svg" icon="eye" @click="toggleInputType('input-password-repeat', 'new-password-repeat')" title="Passwort verstecken" />
                  <font-awesome-icon v-else class="svg" icon="eye-slash" @click="toggleInputType('input-password-repeat', 'new-password-repeat')" title="Passwort anzeigen" />
                </div>
              </div>
            </div>
            <div class="twelve columns">
              <button class="button-primary btn-submit" @click="validateUserInput">Änderungen übernehmen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavigation from '../components/SideNavigation.vue'
import Util from '../mixins/util'
import Modal from '../components/Modal.vue'

export default {
  data () {
    return {
      name: 'Anmeldedaten',
      userCurrentPassword: '',
      userPassword: '',
      userPasswordRepeat: '',
      showCurrentPasswordInput: false,
      showPasswordInput: false,
      showPasswordRepeatInput: false
    }
  },
  methods: {
    toLogin: function () {
        this.$router.push({name: 'Login'});
    },
    validateUserInput: function () {
      if (this.userCurrentPassword === '' && this.userPassword === '' && this.userPasswordRepeat === '') {
        window.dataController.ShowMessage('Es wurden keine Daten zum ändern eingegeben!', false, 'error')
      } else {
        this.changeUserProperties()
      }
    },
    changeUserProperties: async function () {
      var isCurrentPasswordValid = await window.dataController.CheckCurrentPassword({password: this.userCurrentPassword})
      if (isCurrentPasswordValid) {
        var res = await window.dataController.ChangeUserProperties({
          password: this.userPassword,
          passwordRepeat: this.userPasswordRepeat
        })
        console.log('response edit user', res)
        if (res && res.status && res.status === 'ok') {
          window.dataController.ShowMessage('Die Daten wurden erfolgreich übertragen!', false, 'success')
          this.resetForm()
        }
      }
    },
    toggleInputType: function (id, inputName) {
      var input = document.querySelector('#' + id)
      if (input) {
        if (inputName === 'new-password') {
          if (input.type === 'password') {
            input.type = 'text'
            this.showPasswordInput = true
          } else {
            input.type = 'password'
            this.showPasswordInput = false
          }
        } else if (inputName === 'new-password-repeat') {
          if (input.type === 'password') {
            input.type = 'text'
            this.showPasswordRepeatInput = true
          } else {
            input.type = 'password'
            this.showPasswordRepeatInput = false
          }
        } else {
          if (input.type === 'password') {
            input.type = 'text'
            this.showCurrentPasswordInput = true
          } else {
            input.type = 'password'
            this.showCurrentPasswordInput = false
          }
        }
      }
    },
    resetForm: function () {
      this.userCurrentPassword = ''
      this.userPassword = ''
      this.userPasswordRepeat = ''
      this.showCurrentPasswordInput = false
      this.showPasswordInput = false
      this.showPasswordRepeatInput = false
      var divs = document.querySelectorAll('.focus'), i
      for (i = 0; i < divs.length; i++) {
        divs[i].classList.remove('focus')
      }
    }
  },
  mounted: function () {
    this.navigateDependingOnSession()
  },
  components: {
    appSideNavigation: SideNavigation,
    appModal: Modal
  },
  mixins: [Util]
}

</script>

<style scoped>
div.form-container {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  align-self: center;
  box-shadow: 2px 3px 10px 2px black;
}
div.form-container button {
  transition: all 0.6s ease;
}
button.btn-submit {
  width: 100%;
}
div.site-content {
  height: 70%;
}
div.six-columns{
  width: 100%;
}
div.input-div {
  position: relative;
  display: grid;
  grid-template-columns: 93% 7%;
  margin: 15px 0;
  padding: 5px 0;
  border-bottom: 2px solid #999;
}
.input-div:after, .input-div:before {
  content: '';
  position: absolute;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #092A67;
  transition: .3s;
}
.input-div:after {
  right: 50%;
}
.input-div:before {
  left: 50%;
}
div.input-div.focus .icon svg {
  color: #092A67;
}
.input-div.focus div h5 {
  top: -5px;
  font-size: 15px;
}
.input-div.focus:after, .input-div.focus:before {
  width: 50%;
}
div.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.icon svg {
  cursor: pointer;
  color: #999;
  transition: .3s;
}
div.input-div > div {
  position: relative;
  height: 45px;
}
div.input-div > div h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}
.input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #555;
}
.input:focus {
  border: none;
}
.edit-user-message {
  color: red;
}
@media (max-width: 550px) {
  div.main-content-container-closed {
    padding: 12px;
  }
}
</style>
